import React from "react";
import Slider from "react-slick";
import Nav from "./Nav";
import Particles from "./Particles";

const Header = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 12000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    return (
        <>
            <Nav />
            <header className="header-section mb-12 overflow-hidden relative"> {/** header-bg */}
                <video className="absolute top-0 left-0 w-full h-full object-cover z-[-1]" autoPlay loop muted>
                    <source src={require("../assets/videos/JeroenEgelmeers_2024.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70 z-[-1]" />
                <div className="pt-12 lg:pt-36">
                    <div className="banner_section layout_padding mb-10">
                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner z-10">
                                <Slider {...settings}>
                                <div className="carousel-item pb-10">
                                        <h1 className="banner_taital drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]"></h1>
                                        <p className="banner_text drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]"></p>
                                    </div>
                                    <div className="carousel-item pb-10">
                                        <h1 className="banner_taital drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]">Prompt Engineering</h1>
                                        <p className="banner_text drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]">Jeroen is the founder of the Crafting AI Prompts Framework and a Prompt Engineering Advocate at Sogeti Netherlands. Actively involved in the Prompt Engineering community, Jeroen’s framework website serves as your comprehensive guide to mastering Prompt Engineering—whether you’re using ChatGPT, Microsoft Co-pilot, Claude, or any other AI model.
                                            <br />If you want to learn how to craft, generate, or create maintainable and sustainable prompts, this website is for you!
                                        </p>
                                        <div className="read_bt"><a href="https://www.craftingaiprompts.org/" target={"_blank"}>Crafting AI Prompts Framework</a></div>
                                    </div>
                                    <div className="carousel-item active">
                                        <h1 className="banner_taital drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]">Public speaker</h1>
                                        <p className="banner_text drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]">If you're curious about Prompt Engineering or Generative AI, you're in the right place! Jeroen is an experienced speaker who has presented at some of the world’s leading conferences, including ODSC, Devoxx Belgium, the Prompt Engineering Conference, Microsoft Envision, and many more. Jeroen is passionate about sharing insights and driving conversations in this rapidly evolving field.
                                            <br />Explore more to discover the latest trends and innovations shaping the future of AI.</p>
                                        <div className="read_bt"><a href="https://sessionize.com/jeroenegelmeers/" target={"_blank"}>Sessionized profile</a></div>
                                    </div>
                                    <div className="carousel-item active">
                                        <h1 className="banner_taital drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]">Trainer</h1>
                                        <p className="banner_text drop-shadow-[0_3.2px_3.2px_rgba(0,0,0,0.8)]">Jeroen offers in-depth training sessions through Sogeti and the Capgemini Academy, focusing on the rapidly evolving fields of Prompt Engineering and Software Engineering. These sessions are designed to equip professionals with the knowledge and skills needed to harness the power of AI-driven tools and advanced software methodologies. If you're interested in organizing a session for your team, please contact your Sogeti or Capgemini account manager for more information.</p>
                                        <div className="read_bt"><a href="/biography">Biography</a></div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;